















































































































import NetworkCard from "@/components/networks/NetworkCard.vue";
import { AccessModeEnum, BaseNetworkData } from "@/includes/types/networks";
import { getMyNetworks, getNetworkConfig, sortNetworksByChatsLength } from "@/includes/logic/Networks/logic";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";

import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";
import ExtendedHelpMessage from "piramis-base-components/src/components/ExtendedHelpMessage.vue";
import CenteredColumnLayout from "piramis-base-components/src/components/CenteredColumnLayout.vue";

import { Component, Mixins } from 'vue-property-decorator';
import { AxiosError, isAxiosError } from "axios";

@Component({
  components: {
    CenteredColumnLayout,
    ExtendedHelpMessage,
    NetworkCard,
    PageTitle,
    AddEntityCard
  }
})
export default class NetworksList extends Mixins(NetworksHelperMixin) {
  networks: Array<BaseNetworkData> | null = null

  get accessError() {
    const error = this.$store.state.networksState.error

    if (error && isAxiosError(error)) {
      const err: AxiosError = error
      const accessMode = (err.response as any)?.data?.access_mode as BaseNetworkData['access_mode'] | undefined

      if (accessMode) {
        return accessMode
      }
    }

    return null
  }

  get errorMessage() {
    if (this.accessError === AccessModeEnum.All) {
      return this.$t('network_access_mode_error_all').toString()
    } else if (this.accessError === AccessModeEnum.Owner) {
      return this.$t('network_access_mode_error_owner').toString()
    } else {
      return this.$t('network_access_mode_error_other').toString()
    }
  }

  clearError() {
    this.$store.dispatch("pushNetworkError", null)
  }

  destroyed() {
    this.clearError()
  }

  settingClick(network: BaseNetworkData) {
    getNetworkConfig(network.id)
      .then(res => {
        if (res) {
          this.gotoNetDashboard(network.id)
        }
      })
  }

  addNetwork() {
    this.$router.push({
      name: 'NetworksAdd',
    })
  }

  getNetworks() {
    if (!this.accessError) {
      getMyNetworks()
        .then(res => {
          this.networks = sortNetworksByChatsLength(res)
        })
    }
  }

  created() {
    this.getNetworks()
  }
}
