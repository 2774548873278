















































import { BaseNetworkData } from "@/includes/types/networks";
import { getNetworkTitle, getPrivacyAlias } from "@/includes/logic/Networks/utils";

import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {
    Icon
  },
  methods: {
    getPrivacyAlias,
    getNetworkTitle
  }
})
export default class NetworkCard extends Vue {
  @Prop() network!: BaseNetworkData

  get chatsLength() {
    return this.network.chats.length
  }

  get triggersLength() {
    return this.network.triggers.length
  }

  get modulesLength() {
    return this.network.modules.length
  }

  @Emit()
  settingClick() {
    return this.network.id
  }

  @Emit()
  deleteNetwork() {
    return this.network.id
  }
}
